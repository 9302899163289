import React from "react"

import { Button, Typography } from "@mui/material"

function App() {
  return (
    <Button
      sx={{
        textTransform: "none",
        backgroundColor: "lightgrey",
        borderRadius: "20px",
        padding: "5px"
      }}
    >
      <Typography
        color="black"
        fontSize="15px"
        sx={{
          px: "10px",
          py: "5px"
        }}
      >
        Trouver ma taille
      </Typography>
    </Button>
  );
}

export default App;
